$(function () {

    function setSubMenuSpacer() {
        var subMenuHeight = $('#nav-sub-menu').height();
        var subMenuSpacer = subMenuHeight;
        $('.sub-menu-spacer').css("padding-top", subMenuSpacer + "px");
    }

    $(document).ready(function () {
        setSubMenuSpacer();
    });

    $(window).resize(function () {
        if ($("#nav-sub-menu").length) {
            setSubMenuSpacer();
        }
    });
    
    function changeTitleSubtype(val) {
        $('#' + val + '-submenu .dropdown-menu a').click(function () {
            var subType = $(this).text().replace(' Providers', '');
            subType = subType.replace(' Provider Sites', '');
            $('#h1-' + val + ' .current-subtype').html(subType);
        });
    }
    
    $.each(['annual', 'population', 'trends', 'providers', 'provider-sites'], function (key, val) {
        changeTitleSubtype(val);
    });
    
});

/*
 * Horizontal scrolling for sub-menu tabs
 * Sourced via https://www.codeply.com/go/bp/l2ChB4vYmC then modified
 */

$(function() {

    var scrollBarWidths = 100;

    var widthOfList = function () {
        var itemsWidth = 0;
        $('.hscroll-list li').each(function () {
            var itemWidth = $(this).outerWidth();
            itemsWidth += itemWidth;
        });
        return itemsWidth;
    };

    var widthOfHidden = function () {
        return (($('.hscroll-wrapper').outerWidth()) - widthOfList() - getLeftPosi()) - scrollBarWidths;
    };

    var getLeftPosi = function () {
        return $('.hscroll-list').position().left;
    };

    var reAdjust = function () {
        if($('#tab-section').length) {
            if (($('.hscroll-wrapper').outerWidth()) < widthOfList()) {
                $('.scroller-right').show();
            } else {
                $('.scroller-right').hide();
            }

            if (getLeftPosi() < 0) {
                $('.scroller-left').show();
            } else {
                $('.item').animate({left: "-=" + getLeftPosi() + "px"}, 'slow');
                $('.scroller-left').hide();
            }
        }
    };

    reAdjust();

    $(window).on('resize', function (e) {
        reAdjust();
    });

    $('.scroller-right').click(function () {
        //console.log("widthOfHidden: " + widthOfHidden());
        $('.hscroll-list').animate({left: "+=-" + scrollBarWidths + "px"}, 'slow', function () {
            if (widthOfHidden() + scrollBarWidths >= 0) {
                $('.scroller-right').fadeOut('fast');
            } else {
                reAdjust();
            }
        });
    });

    $('.scroller-left').click(function () {
        //console.log("getLeftPosi: " + getLeftPosi());
        $('.hscroll-list').animate({left: "-=-" + scrollBarWidths + "px"}, 'slow', function () {
            if (getLeftPosi() + scrollBarWidths > 0) {
                $('.scroller-left').fadeOut('fast');
            } else {
                reAdjust();                
            }
        });
    });

});
